import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';

export const JUST_FOR_YOU_ENTRY = gql`
	query JustForYouEntry($location: String!, $selector: String!) {
		${ImageFields}
		dynamicYieldRecommendations(input: { selectors: [$selector], location: $location }) {
			title
			subTitle
			bannerImage {
				...ImageFields
			}
			callToAction {
				url
				name
			}
			decisionId
			analyticsData {
				campaignName
				experienceName
				variationName
			}
		}
	}
`;

export const REPORT_JUST_FOR_YOU_ENTRY_CLICK = gql`
	mutation ReportJustForYouEntryClick($decisionId: String!) {
		reportCustomCodeClick(input: { decisionId: $decisionId })
	}
`;
