import React, { type FunctionComponent } from 'react';

export type RichTextContentProps = {
	rawHtml: string;
	className?: string;
};

export const RichTextContent: FunctionComponent<RichTextContentProps> = ({ rawHtml, className = '' }) => (
	<div data-testid="richTextContent" className={`rich-text ${className}`} dangerouslySetInnerHTML={{ __html: rawHtml }}></div>
);
