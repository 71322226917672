export const OMC_HOSTNAME = 'omconsole.com';

export const INVENTORY_BUG_FORM_URL =
	'https://wolseley.service-now.com/x/wols2/build-with-ferguson/catalog/395385e5870a25d0eb16646d8bbb35b1/params/';
export const DATA_BUG_FORM_URL = 'https://airtable.com/appQH5y4K6BnTt7uz/pag8dlQJJ0jeZu5CJ/form';
export const PRICING_BUG_FORM_URL =
	'https://forms.office.com/Pages/ResponsePage.aspx?id=NYQvPEyZUkWP6CrsLQgi5Me-LHtL3glCsLUbGSyNZHBUME83SlRRUlFMUFdWUDAzVUhWNTgyVElRUi4u';

export const SITE_ID = 82;

/**
 * Pricebook IDs used for build.com sites.
 */
export enum PRICEBOOK_IDS {
	CONSUMER_PRICEBOOK_ID = 1,
	PRO_PRICEBOOK_ID = 22,
	TRILOGIE_PRICEBOOK_ID = 999
}
