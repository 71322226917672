import { useQuery } from '@apollo/client/react/hooks';
import React, { type FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { configureRoute } from '../../../../utils/routes/configure-route';
import type { CategorySearchQuery, CategorySearchQueryVariables } from '../../../__generated__/graphql-client-types';
import { PageLoading } from '../../../components/common-components/loading/loading.component';
import { FourOFour } from '../../../components/error-components/404/404.component';
import { FiveHundred } from '../../../components/error-components/500/500.component';
import { RedirectWithStatus } from '../../../components/redirect-w-status';
import { CATEGORY_ROUTE } from '../../../constants/links';
import { populateCategoryData } from '../../../helpers/datalayer-helper/internal-datalayer.helper';
import { useEmployee } from '../../../hooks/apollo/employee/employee.hooks';
import { CATEGORY } from '../../../queries/category/category.queries';
import { type Category } from '../../../types/search.types';
import { CategoryBrowsePage } from '../category-browse/category-browse.page';
import { CategoryProductDrop } from '../category-product-drop/category-product-drop.page';

export const CategoryRouter: FunctionComponent = () => {
	const { pathname, search } = useLocation();
	const { category: categoryPathArg } = useParams<{ category: string }>();
	const categoryId = !isNaN(Number(categoryPathArg)) ? Number(categoryPathArg) : 0;
	const { data, loading, error } = useQuery<CategorySearchQuery, CategorySearchQueryVariables>(CATEGORY, {
		variables: {
			categoryId,
			featuredOnly: false
		},
		// when back button clicked from ProductDrop, apollo was making a network request
		// which caused a longer load time and a scroll to top of page
		fetchPolicy: 'cache-first'
	});
	const { employee } = useEmployee();

	const isCategoryResult = (result: any): result is Category => {
		return result?.__typename === 'Category';
	};

	if (error) {
		return <FiveHundred />;
	}

	if (loading) {
		return <PageLoading />;
	}

	const categoryData = data?.category;

	if (!isCategoryResult(categoryData)) {
		return <FourOFour pageName="category" />;
	}

	// Fix category slug to use the proper url: performs a redirection if it isn't.
	if (pathname !== categoryData.url) {
		return <RedirectWithStatus path={pathname} to={`${categoryData.url}${search}`} />;
	}

	// populate datalayer for internal tools
	if (employee.isAuthenticated) {
		populateCategoryData(categoryId);
	}

	// route to browse page if the category has no products
	if (!categoryData.hasProducts) {
		return <CategoryBrowsePage category={categoryData} />;
	}

	return <CategoryProductDrop category={categoryData} />;
};

export const RoutedCategoryRouter = configureRoute(
	{
		path: [CATEGORY_ROUTE],
		exact: false,
		DANGEROUSLY_IS_LIVE: true,
		ddRum: { rumViewNameSetByChild: true }
	},
	CategoryRouter
);
