import React, { type FunctionComponent, useState, useEffect } from 'react';
import { type GroupContentFieldsFragment } from '../../__generated__/graphql-client-types';
import { filterItemsByActiveFacets, flattenSelectedFacets } from '../../helpers/category-helper/category.helper';
import { useSearchResults } from '../../hooks/apollo/search/search.hooks';
import { type GroupContentItem } from '../../types/content.types';
import { Carousel } from '../carousel/carousel.component';
import { PromotedFilterWithImage } from '../search-components/new-facet-filter-list/promoted-filter-with-image/promoted-filter-with-image.component';

export type PromotedFilterListV1Props = {
	groups: GroupContentFieldsFragment[];
};

export const PromotedFilterListV1: FunctionComponent<PromotedFilterListV1Props> = ({ groups }) => {
	const { results } = useSearchResults();
	const contentItems: GroupContentItem[] = groups.flatMap((group) => group.items);
	const [activeFacetValues, setActiveFacetValues] = useState<string[]>([]);
	const [filteredItems, setFilteredItems] = useState<GroupContentItem[]>([]);
	const [shouldUpdateFilteredItems, setShouldUpdateFilteredItems] = useState(false);

	useEffect(() => {
		const selectedFacets = results?.selectedFacetGroups.flatMap((group) => group.facets);
		if (selectedFacets && selectedFacets.length !== activeFacetValues.length && results) {
			const newFilterValues = flattenSelectedFacets(results.selectedFacetGroups);
			setActiveFacetValues([...newFilterValues]);
			setShouldUpdateFilteredItems(true);
		}
	}, [results]);

	useEffect(() => {
		if (shouldUpdateFilteredItems) {
			const updatedItemsList = filterItemsByActiveFacets(contentItems, activeFacetValues);
			setFilteredItems([...updatedItemsList]);
			setShouldUpdateFilteredItems(false);
		}
	}, [activeFacetValues]);

	useEffect(() => {
		if (contentItems.length > 0) {
			setFilteredItems(contentItems);
		}
	}, [contentItems.length]);

	return filteredItems.length ? (
		<div data-testid="promoted-filter-list-v1">
			<Carousel>
				{filteredItems.map((item, index) => (
					<PromotedFilterWithImage key={`${item.url}-${index}`} item={item} bypassLazyLoad={true} />
				))}
			</Carousel>
		</div>
	) : null;
};
