import loadable, { type LoadableComponent } from '@loadable/component';
import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent, useEffect } from 'react';
import { type ContentPromotionVariation } from '../../__generated__/graphql-client-types';
import { AnalyticsHelper } from '../../helpers/analytics/analytics.helper';
import { buildGTMPromoBannerClick, buildGTMPromoBannerDisplay } from '../../helpers/analytics/gtm/event-builders';
import { handleKeys } from '../../helpers/keyboard/keyboard.helper';
import { type PromoBannerOptions, type PromoBannerVariationOptions } from '../../helpers/recommendations/recommendations.helper';
import { useTrackEvent } from '../../hooks/analytics/analytics.hooks';
import { type PromoAction, type VideoLink } from '../../types/content.types';
import { Link } from '../common-components/link/link.component';
import { VideoModal } from '../common-components/video-modal/video-modal.component';
import { ContentContainer } from '../content-container/content-container.component';

const LoadablePromoBannerA = loadable(() => import(/* webpackChunkName: "promo-banner-a" */ './variations/promo-banner-a.component'), {
	resolveComponent: ({ PromoBannerA }) => PromoBannerA
});

const LoadablePromoBannerB = loadable(() => import(/* webpackChunkName: "promo-banner-b" */ './variations/promo-banner-b.component'), {
	resolveComponent: ({ PromoBannerB }) => PromoBannerB
});

const LoadablePromoBannerC = loadable(() => import(/* webpackChunkName: "promo-banner-c" */ './variations/promo-banner-c.component'), {
	resolveComponent: ({ PromoBannerC }) => PromoBannerC
});

const LoadablePromoBannerD = loadable(() => import(/* webpackChunkName: "promo-banner-d" */ './variations/promo-banner-d.component'), {
	resolveComponent: ({ PromoBannerD }) => PromoBannerD
});

const LoadablePromoBannerE = loadable(() => import(/* webpackChunkName: "promo-banner-e" */ './variations/promo-banner-e.component'), {
	resolveComponent: ({ PromoBannerE }) => PromoBannerE
});

const LoadableSecondaryPromoA = loadable(
	() => import(/* webpackChunkName: "secondary-promo-a" */ './secondary-variations/secondary-promo-a.component'),
	{ resolveComponent: ({ SecondaryPromoA }) => SecondaryPromoA }
);

const LoadableSecondaryPromoB = loadable(
	() => import(/* webpackChunkName: "secondary-promo-b" */ './secondary-variations/secondary-promo-b.component'),
	{ resolveComponent: ({ SecondaryPromoB }) => SecondaryPromoB }
);

const LoadableSecondaryPromoC = loadable(
	() => import(/* webpackChunkName: "secondary-promo-c" */ './secondary-variations/secondary-promo-c.component'),
	{ resolveComponent: ({ SecondaryPromoC }) => SecondaryPromoC }
);

const LoadableSecondaryPromoD = loadable(
	() => import(/* webpackChunkName: "secondary-promo-d" */ './secondary-variations/secondary-promo-d.component'),
	{ resolveComponent: ({ SecondaryPromoD }) => SecondaryPromoD }
);

const LoadableSecondaryPromoE = loadable(
	() => import(/* webpackChunkName: "secondary-promo-e" */ './secondary-variations/secondary-promo-e.component'),
	{ resolveComponent: ({ SecondaryPromoE }) => SecondaryPromoE }
);

const PRIMARY_PROMO_BANNER_VARIATION_BY_CODE = {
	A: LoadablePromoBannerA,
	B: LoadablePromoBannerB,
	C: LoadablePromoBannerC,
	D: LoadablePromoBannerD,
	E: LoadablePromoBannerE,
	// These don't have their own implementations (yet) so we re-use the A variation
	F: LoadablePromoBannerA,
	G: LoadablePromoBannerA
};

const SECONDARY_PROMO_BANNER_VARIATION_BY_CODE = {
	A: LoadableSecondaryPromoA,
	B: LoadableSecondaryPromoB,
	C: LoadableSecondaryPromoC,
	D: LoadableSecondaryPromoD,
	E: LoadableSecondaryPromoE,
	// These don't have their own implementations (yet) so we re-use the A variation
	F: LoadableSecondaryPromoA,
	G: LoadableSecondaryPromoA
};

export const selectPromoBannerVariation = (
	variationCode: ContentPromotionVariation,
	isPrimaryPosition: boolean
): LoadableComponent<PromoBannerVariationOptions> => {
	const variationMap = isPrimaryPosition ? PRIMARY_PROMO_BANNER_VARIATION_BY_CODE : SECONDARY_PROMO_BANNER_VARIATION_BY_CODE;
	const variation = variationMap[variationCode];
	if (!variation) {
		logError(`Unrecognized or unimplemented promo banner variation code: ${variationCode}`);
	}
	return variation || null;
};

const isActionVideoLink = (action: PromoAction): action is VideoLink => action.__typename === 'VideoLink';

/**
 * PromoBanner accepts the variation and determines which Promo Banner variation
 * to load.  This is to prevent a page using one variation from requiring all of
 * the js for each variation to be part of the bundle
 */
export const PromoBanner: FunctionComponent<PromoBannerOptions> = ({ variation, isJustForYouBanner = false, ...variationProps }) => {
	const { id, action, priority } = variationProps;
	// Non-primary positions have priority values like 'TOP_SECONDARY' or 'BOTTOM_SECONDARY'
	const isPrimaryPosition = !priority.includes('SECONDARY');
	const isTop = priority === 'TOP' || priority === 'TOP_SECONDARY';
	const loadVariation = () => {
		const LoadableBanner = selectPromoBannerVariation(variation, isPrimaryPosition);
		return LoadableBanner ? <LoadableBanner {...variationProps} isJustForYouBanner={isJustForYouBanner} /> : null;
	};
	const bannerUrl = action && !isActionVideoLink(action) ? action.url : '';
	const handleOnClick = () => {
		AnalyticsHelper.track(buildGTMPromoBannerClick(priority, bannerUrl)).catch(logError);
	};
	const trackEvent = useTrackEvent();
	// Fire off only once
	useEffect(() => {
		trackEvent(buildGTMPromoBannerDisplay(priority));
	}, []);
	return (
		<ContentContainer contentId={id}>
			<section>
				<div
					onClick={handleOnClick}
					onKeyDown={handleKeys(['Enter', ' '], handleOnClick)}
					role="button"
					tabIndex={0}
					data-testid="promobanner"
					data-contentitemid={id}
					className={`w-100 ${isPrimaryPosition ? '' : 'dn db-l'} ${isTop ? 'mb3' : 'mv3'}`}>
					{action && isActionVideoLink(action) ? (
						<VideoModal video={action.video}>{loadVariation()}</VideoModal>
					) : bannerUrl ? (
						<Link url={bannerUrl} analyticsHook={`promobanner-${id}-${priority}`}>
							{loadVariation()}
						</Link>
					) : (
						<div>{loadVariation()}</div>
					)}
				</div>
			</section>
		</ContentContainer>
	);
};
