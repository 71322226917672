import React, { type FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SITE_PATH } from '../../../../constants/links';
import {
	findIsSelected,
	convertConstructFacetsToFacetFilters,
	getNonCompatibleFacets
} from '../../../../helpers/content-helper/content.helper';
import { handleKeys } from '../../../../helpers/keyboard/keyboard.helper';
import { useSearchResults } from '../../../../hooks/apollo/search/search.hooks';
import { useNavigation } from '../../../../hooks/navigation/navigation.hooks';
import { type GroupContentItem } from '../../../../types/content.types';
import { ClickableElement } from '../../../buttons';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';

const ITEM_WIDTH = 50;
const ITEM_HEIGHT = 50;

export type PromotedFilterWithImageProps = {
	item: GroupContentItem;
	bypassLazyLoad: boolean;
};

export const PromotedFilterWithImage: FunctionComponent<PromotedFilterWithImageProps> = ({ item, bypassLazyLoad }) => {
	const [isSelected, setIsSelected] = useState(false);
	const { addFacets, removeFacets, results, clearAndAddFacets, loading } = useSearchResults();
	const navigate = useNavigation();
	const { pathname } = useLocation();
	const { facetGroups, selectedFacetGroups } = results ?? {};

	useEffect(() => {
		if (loading) {
			return;
		}
		if (findIsSelected(item.facets, selectedFacetGroups)) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	}, [item, selectedFacetGroups, loading]);

	const handleClick = () => {
		if (item.url) {
			const parsedUrl = new URL(item.url, SITE_PATH);
			if (parsedUrl.pathname !== pathname) {
				navigate(item.url);
				return;
			}
		}

		const facets = convertConstructFacetsToFacetFilters(item.facets);
		if (isSelected) {
			removeFacets(facets);
			setIsSelected(false);
		} else {
			const nonCompatibleFacetsArray = getNonCompatibleFacets(item, facetGroups, selectedFacetGroups);
			if (nonCompatibleFacetsArray.length === 0) {
				addFacets(facets);
			} else {
				clearAndAddFacets(facets);
			}
			setIsSelected(true);
		}
	};

	return (
		<div style={{ minWidth: 99 }} data-testid="promoted-filter-with-image">
			<ClickableElement
				onClick={handleClick}
				onKeyDown={handleKeys(['Enter', ' '], handleClick)}
				ariaLabel={`Apply filter ${item.title}`}
				className={`w-100 h-100 pa2 theme-grey-darker bg-theme-white ${isSelected ? 'dn' : ''}`}>
				<div>
					{item.image?.id && (
						<CloudinaryImage
							publicID={item.image.id}
							description={item.image.description}
							options={{ width: ITEM_WIDTH, height: ITEM_HEIGHT }}
							useDimensions={true}
							bypassLazyLoad={bypassLazyLoad}
							className="db center mw-100"
						/>
					)}
					<div className="f6 tc tc2-title mt2">{item.title === '' ? item.facets[0].value : item.title}</div>
				</div>
			</ClickableElement>
		</div>
	);
};
