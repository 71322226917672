import { useLocation } from 'react-router-dom';

/**
 * Hook to test if the path provided matches the current location.
 * Can be useful to determine if the user is on a specific page.
 */
export function useIsCurrentLocation(path: string) {
	const location = useLocation();
	return path === location.pathname;
}
