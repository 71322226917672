import React, { type FunctionComponent, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { SITE_PATH } from '../../../../constants/links';
import {
	convertConstructFacetsToFacetFilters,
	findIsSelected,
	getNonCompatibleFacets
} from '../../../../helpers/content-helper/content.helper';
import { handleKeys } from '../../../../helpers/keyboard/keyboard.helper';
import { useSearchResults } from '../../../../hooks/apollo/search/search.hooks';
import { useClientOnlyBreakpoints } from '../../../../hooks/breakpoints/client-only-breakpoints.hook';
import { useNavigation } from '../../../../hooks/navigation/navigation.hooks';
import { type GroupContentItem } from '../../../../types/content.types';
import { ClickableElement } from '../../../buttons';
import { AddIcon } from '../../../svg/icons.component';
import { innerContentInnerPadding, topAndBottomPaddingOnDesktop, topAndBottomPaddingOnMobile } from './promoted-filter-pill.css';

export type PromotedFilterPillProps = {
	item: GroupContentItem;
};

export const PromotedFilterPill: FunctionComponent<PromotedFilterPillProps> = ({ item }) => {
	const { isNotSmall } = useClientOnlyBreakpoints();
	const [isSelected, setIsSelected] = useState(false);
	const { addFacets, removeFacets, results, clearAndAddFacets, loading } = useSearchResults();
	const navigate = useNavigation();
	const { pathname } = useLocation();
	const { facetGroups, selectedFacetGroups } = results ?? {};

	useEffect(() => {
		if (loading) {
			return;
		}
		if (findIsSelected(item.facets, selectedFacetGroups)) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	}, [item, selectedFacetGroups, loading]);

	const handlePromotedFilterPillClick = () => {
		if (item.url) {
			const parsedUrl = new URL(item.url, SITE_PATH);
			if (parsedUrl.pathname !== pathname) {
				navigate(item.url);
				return;
			}
		}

		const facets = convertConstructFacetsToFacetFilters(item.facets);
		if (isSelected) {
			setIsSelected(false);
			removeFacets(facets);
		} else {
			setIsSelected(true);
			const nonCompatibleFacetsArray = getNonCompatibleFacets(item, facetGroups, selectedFacetGroups);
			if (nonCompatibleFacetsArray.length === 0) {
				addFacets(facets);
			} else {
				clearAndAddFacets(facets);
			}
		}
	};

	return (
		<div className="dib">
			<ClickableElement
				onClick={handlePromotedFilterPillClick}
				onKeyDown={handleKeys(['Enter', ' '], handlePromotedFilterPillClick)}
				ariaLabel={`Add filter: ${item.title}`}
				className="flex items-center">
				<div className={`${innerContentInnerPadding} br-pill b--theme-black b--dotted bw1 f5 items-center inline-flex`}>
					<AddIcon className="f4 mr1" />
					<span className={`${isNotSmall ? topAndBottomPaddingOnDesktop : topAndBottomPaddingOnMobile} nowrap`}>
						{item.title === '' ? item.facets[0].value : item.title}
					</span>
				</div>
			</ClickableElement>
		</div>
	);
};
