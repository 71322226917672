import React, { type FunctionComponent, type HTMLAttributes, type ReactElement } from 'react';
import { type Maybe, type DefaultCategoryFieldsFragment } from '../../../../../__generated__/graphql-client-types';
import { type CategoryTileProps } from '../category-tile/category-tile.component';

export type CategoryBrowseListProps = {
	title?: string;
	titleClass?: string;
	items: Maybe<DefaultCategoryFieldsFragment[]>;
	itemTransform: (current: CategoryTileProps, index: number, info: { isFirst: boolean; isLast: boolean }) => ReactElement;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

/**
 * Category Browse List Component
 */
export const CategoryBrowseList: FunctionComponent<CategoryBrowseListProps> = ({
	className = 'items-center justify-start flex-wrap',
	title,
	titleClass = 'ba-l b--theme-grey-light pa3',
	items,
	itemTransform
}) => {
	return items && items.length > 0 ? (
		<ul className={`list flex flex-column pa0 ma0 ${className}`}>
			{title && <li className={`b f4 w-100 ${titleClass}`}>{title}</li>}
			{items.map((category, index) => itemTransform(category, index, { isFirst: index === 0, isLast: index === items.length - 1 }))}
		</ul>
	) : null;
};
